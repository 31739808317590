<template>
  <b-card>
    <b-static-table
      :items="mappedClients"
      :fields="clientsTableFields"
      :loading="loading"
      :button-text="$t('Nou client')"
      show-button
      show-search
      @button-click="onNewClientButtonClicked"
      @item-click="onClientClicked"
      @item-middle-click="onClientMiddleClicked"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import {
  getAccommodationTypeName,
  getRealEstateOperationName,
  notifyError,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BStaticTable,
  },
  data() {
    return {
      clientsTableFields: [
        { key: "fullName", label: this.$t("Nom"), sortable: true },
        { key: "phone", label: this.$t("Telèfon"), sortable: true },
        { key: "email", label: this.$t("Email"), sortable: true },
        { key: "operation", label: this.$t("Operació"), sortable: true },
        { key: "interests", label: this.$t("Interessos"), sortable: true },
        { key: "cities", label: this.$t("Pobles"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["realEstateClients/loading"];
    },
    clients() {
      return this.$store.getters["realEstateClients/clients"];
    },
    mappedClients() {
      if (!this.clients?.length) {
        return [];
      }

      return this.clients.map((client) => ({
        uuid: client.uuid || this.$t("No definit"),
        fullName: this.getFullName(client) || this.$t("No definit"),
        phone: client.phone || this.$t("No definit"),
        email: client.email || this.$t("No definit"),
        operation:
          getRealEstateOperationName(client.operation) || this.$t("No definit"),
        interests: this.getClientInterests(client) || this.$t("No definit"),
        cities: this.getClientCities(client) || this.$t("No definit"),
      }));
    },
    cities() {
      return this.$store.getters["places/cities"];
    },
  },
  created() {
    if (!this.clients?.length && !this.loading) {
      this.fetchClients();
    }
    if (!this.cities?.length) {
      this.fetchPlaces();
    }
  },
  methods: {
    fetchClients() {
      this.$store
        .dispatch("realEstateClients/fetchClients", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchClients.title"),
            this.$t("errors.fetchClients.description")
          )
        );
    },
    fetchPlaces() {
      // TODO: only fetch place of type CITY
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    onNewClientButtonClicked() {
      this.$router.push({
        name: "foravila-new-real-estate-client",
      });
    },
    onClientClicked(clientUuid) {
      this.$router.push({
        name: "foravila-real-estate-client-view",
        params: { clientUuid },
      });
    },
    onClientMiddleClicked(clientUuid) {
      const routeData = this.$router.resolve({
        name: "foravila-real-estate-client-view",
        params: { clientUuid },
      });
      window.open(routeData.href, "_blank");
    },
    getFullName(client) {
      const fullName = [];
      if (client.firstName) fullName.push(client.firstName);
      if (client.lastName) fullName.push(client.lastName);
      return fullName.length ? fullName.join(" ") : null;
    },
    getClientInterests(client) {
      if (!client?.interests?.length) return null;
      return client.interests
        .map((interest) => {
          return getAccommodationTypeName(interest);
        })
        .join(", ");
    },
    getClientCities(client) {
      if (!client?.cities?.length) return null;
      return client.cities
        .map((city) => {
          return this.getCityName(city);
        })
        .join(", ");
    },
    getCityName(citySlug) {
      const foundCity = this.cities.find((city) => city.slug === citySlug);
      return foundCity ? foundCity.name : citySlug;
    },
  },
};
</script>
